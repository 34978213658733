import { Component, Vue } from 'vue-property-decorator';
// layouts
import layout from '@/layouts/basic/basic.vue';

import links from '@/services/links';

import CarouselNews1 from '@/modules/news/components/carouselNews1/carouselNews1.vue';
import ListRecentNews from '@/modules/news/components/listRecentNews/listRecentNews.vue';

import Title1 from '@/components/title1/title1.vue';
import CardBtn from '@/components/cardBtn/cardBtn.vue';
import BannerCorona from '@/components/bannerCorona/bannerCorona.vue';
import FeedSocialMedia from '@/components/feedSocialMedia/feedSocialMedia.vue';
import BannersConsorcios from '@/components/bannersConsorcios/bannersConsorcios.vue';
import NotaValePremios from '@/components/notaValePremios/notaValePremios.vue';
import ParticiacoesPublicas from '@/components/particiacoesPublicas/particiacoesPublicas.vue';

@Component({
  components: {
    layout,
    Title1,
    CardBtn,
    CarouselNews1,
    BannerCorona,
    ListRecentNews,
    FeedSocialMedia,
    BannersConsorcios,
    NotaValePremios,
    ParticiacoesPublicas
  },
})
export default class Home extends Vue {

  links = links;

  get isMobile(): boolean{
    return this.$store.getters['screen/isMobile'];
  }

  clickItemAcesso(url: any){
    if(url.search("http") > -1 ){
      // window.open(url); 
      window.open(url, '_blank');
    }
    else{
      this.$router.push(url);
    }
  }


  mounted(): void {
    // 
  }
}
import { Component, Vue } from 'vue-property-decorator';
import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL';
import posts from '@/modules/posts/services/posts';
import Title1 from '@/components/title1/title1.vue';
import mapPagesWordpress from '@/statics/mapPagesWordpress.json'

  
@Component({
  components: {
    Title1
  }
})
export default class ParticiacoesPublicas extends Vue {
  mapPagesWordpress = mapPagesWordpress
}
  
import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons
  }
})
export default class CardBtn extends Vue {
  @Prop({default: ''}) iconLibrary!: string;
  @Prop({default: ''}) title!: string;
  @Prop({default: 'clipboard'}) iconName!: string;
  @Prop({default: false}) isSubtitle!: boolean;
  @Prop({default: '1'}) size!: string;


  getLibrary(): string{
    return (this.iconLibrary == '') ? 'mdi' : this.iconLibrary;
  }

  getSize(): string{
    if(this.size == '2'){
      return 'height: 100px;'
    }
    return 'height: 64px;'
  }

}
  